import * as React from "react";
import { Pagination } from "react-bootstrap";
import styles from "./SimplePagination.module.scss";
import Link from "next/link";
import classNames from "classnames";

type Props = {
  pages: number;
  active: number;
  href: (page: number) => string;
  onClick: (which: number) => void;
};

export const PER_PAGE = 19;

export const parsePage = (page: number | string | undefined): number => {
  if (typeof page === "string") {
    const number = parseInt(page, 10);
    return !isNaN(number) && number > 0 ? number : 1;
  }

  if (typeof page === "number") {
    return page > 0 ? page : 1;
  }

  return 1;
};

export const SimplePagination = ({ pages, active, onClick, href }: Props): JSX.Element => {
  const p: number[] = [];

  if (active > pages) {
    active = pages;
    console.error("Active is more than the errors.");
  }

  for (let i = Math.max(1, active - 5); i < active; i++) {
    p.push(i);
  }
  if (p.indexOf(active) === -1) {
    p.push(active);
  }
  const totalAfter = active + 5 < pages ? active + 5 : pages;
  for (let i = active + 1; i < totalAfter + 1; i++) {
    p.push(i);
  }

  return (
    <div className={styles.simplePagination}>
      <Pagination className="justify-content-center">
        {p.map((pag) => (
          <li key={pag} className={classNames("page-item", { active: pag === active })}>
            {href ? (
              <Link
                scroll={false}
                href={href ? href(pag) : undefined}
                onClick={onClick ? () => onClick(pag) : undefined}
              >
                <span className="page-link">{pag}</span>
              </Link>
            ) : (
              <a onClick={onClick ? () => onClick(pag) : undefined} style={{ cursor: "pointer" }}>
                <span className="page-link">{pag}</span>
              </a>
            )}
          </li>
        ))}
      </Pagination>
    </div>
  );
};
