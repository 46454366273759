import { BaseProductVariationFragment, BaseSimpleProductFragment, BaseVariableProductFragment } from "../api/types";

export const CAT_GLASSES = "remeliai";
export const CAT_LENSES = "lesiai";
export const CAT_BEST_SELLERS = "geriausiai-parduodami";
export const CAT_NEW = "naujienos";
export const CAT_INDEX_PAGE_ID = 148;

export const isAvailable = (product: BaseProductVariationFragment | BaseSimpleProductFragment): boolean => {
  if (product.__typename === "SimpleProduct") {
    return product.stockStatus === "IN_STOCK" && product.stockQuantity > 0;
  }

  return product.stockStatus === "IN_STOCK" && product.stockQuantity > 0;
};

export const formatPrice = (number: number | string, places = undefined, currencySymbol = "€"): string => {
  if (typeof number === "string") {
    number = parseNumber(number);
  }
  return `${currencySymbol}${places ? number.toFixed(places) : number}`;
};

export const addPrices = (rawFormat1: string | number, rawFormat2: string | number): string => {
  return formatPrice(parseNumber(rawFormat1) + parseNumber(rawFormat2));
};

export const parseNumber = (number: string | number | undefined): number => {
  if (typeof number === "undefined" || number === null) {
    return 0;
  }

  if (typeof number === "number") {
    return number;
  }
  const number1 = parseFloat(number.replace(/[€$]/, ""));
  return isNaN(number1) ? undefined : number1;
};

export const findVariant = (product: BaseVariableProductFragment, variantId: number): BaseProductVariationFragment => {
  return product.variations.nodes.find((v) => v.databaseId === variantId);
};

// export const hasAtLeastOneStock = (product: BaseProductType): boolean => {
//   if (product.__typename === "SimpleProduct") {
//     return isAvailable(product);
//   }
//
//   if (product.__typename === "VariableProduct") {
//     return product.variations.nodes.some(isAvailable);
//   }
//
//   return false;
// };
