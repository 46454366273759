import * as React from "react";
import styles from "./Color.module.scss";
import classNames from "classnames";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { OverlayInjectedProps } from "react-bootstrap/Overlay";
import { replaceSymbols } from "../../lib/util/Lithianian";

const colorsMap = {
  juoda: {
    color: "#000",
    name: "juoda",
  },
  aukso: {
    color: "#eedf93",
    name: "auksinė",
  },
  bordo: {
    color: "#5c3585",
    name: "bordo",
  },
  sidabro: {
    color: "#e1e1e1",
    name: "sidabrinė",
  },
  melyna: {
    color: "#476fbf",
    name: "mėlyna",
  },
  melsva: {
    color: "#a3bce8",
    name: "mėlsva",
  },
  ruda: {
    color: "#927c62",
    name: "ruda",
  },
  violetine: {
    color: "#a194dc",
    name: "violetinė",
  },
  rozine: {
    color: "#ffdbdb",
    name: "rožinė",
  },
  oranzine: {
    color: "#ffaf00",
    name: "oranžinė",
  },
  zalia: {
    color: "#9aef76",
    name: "žalia",
  },
  rausva: {
    color: "#ffdbdb",
    name: "rausva",
  },
  rausvo: {
    color: "#ffdbdb",
    name: "rausva",
  },
  pilka: {
    color: "#9c9c9c",
    name: "pilka",
  },
  skaidri: {
    color: "#fff",
    name: "skaidri",
  },
  geltona: {
    color: "#ffd425",
    name: "geltona",
  },
  raudona: {
    color: "#ff0000",
    name: "raudona",
  },
};

export type ColorCode = keyof typeof colorsMap;

function replace(colors: string): ColorCode[] {
  return replaceSymbols(colors.toLowerCase()).replace(/ /g, "-").split(/[/-]/) as ColorCode[];
}

export const mainColor = (colors: ColorCode[] | string): string => {
  const c: ColorCode[] = (typeof colors === "string" ? replace(colors) : colors).filter(
    (key) => typeof colorsMap[key] !== "undefined",
  );

  return c.length > 0 ? convert(c[0]) : colorsMap.skaidri.color;
};

export const Color = (props: {
  size?: number;
  colors: ColorCode[] | string;
  className?: string;
  round?: boolean;
}): JSX.Element => {
  const colors: ColorCode[] =
    typeof props.colors === "string" ? replace(props.colors) : props.colors.map((f) => replace(f)).flat(2);

  const style: React.CSSProperties = {};
  const size = props.size || 20;
  style.width = size - 6;
  style.height = size - 6;
  if (colors.length === 1) {
    style.background = convert(colors[0]);
  } else {
    style.backgroundImage = `linear-gradient(${colors.map((color) => convert(color))})`;
  }

  const renderTooltip = (p: OverlayInjectedProps) => {
    const colorNames = colors.map((color) => (colorsMap[color] ? colorsMap[color].name : color)).join(", ");
    return (
      <Tooltip id="color-tooltip" {...p}>
        {colorNames.charAt(0).toUpperCase() + colorNames.slice(1)}
      </Tooltip>
    );
  };

  return (
    <OverlayTrigger overlay={renderTooltip}>
      <div
        className={classNames(styles.color, props.className, { [styles.round]: props.round })}
        style={{ width: size, height: size }}
      >
        <div style={style} />
      </div>
    </OverlayTrigger>
  );
};

const convert = (color: string): string => {
  if (typeof colorsMap[color] !== "object") {
    return colorsMap.skaidri.color;
  }

  return colorsMap[color as keyof typeof colorsMap].color;
};
