import * as React from "react";
import { useEffect } from "react";
import styles from "./Favorite.module.scss";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import classNames from "classnames";
import { AppContext } from "../context/AppContext";

type Props = {
  productId: number;
  size?: "lg";
};

export const Favorite = ({ productId, size }: Props): JSX.Element => {
  const { favorites, setFavorites } = React.useContext(AppContext);
  const [favorite, setFavorite] = React.useState(false);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (favorite) {
      setFavorites(favorites.filter((id) => productId !== id));
    } else {
      setFavorites([productId, ...favorites]);
    }
    setFavorite(!favorite);
  };

  useEffect(() => {
    setFavorite(favorites.indexOf(productId) !== -1);
  }, [favorites, productId]);

  return (
    <>
      {favorite ? (
        <button
          aria-label="Un-favorite"
          onClick={handleClick}
          className={classNames(styles.favorite, { [styles.love]: favorite })}
        >
          <FontAwesomeIcon icon={faHeart} size={size} />
        </button>
      ) : (
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip id="favorite" className={styles.tooltip}>
              Patinka
            </Tooltip>
          }
        >
          <button
            aria-label="Favorite"
            onClick={handleClick}
            className={classNames(styles.favorite, { [styles.love]: favorite })}
          >
            <FontAwesomeIcon icon={faHeart} size={size} />
          </button>
        </OverlayTrigger>
      )}
    </>
  );
};
