import { PER_PAGE } from "../pagination/SimplePagination";

const logicalOperators = {
  and: ["and", "ir"],
};

const filters = {
  categories: {
    pathname: ["glasses", "akiniai"],
  },
  PaMedziaga: {
    pathname: ["material", "medžiaga", "medziaga"],
  },
  PaSpalva: {
    pathname: ["color", "spalva"],
  },
  PaForma: {
    pathname: ["forma", "form"],
  },
};

export type AttributeTypes = {
  PaForma: Array<string>;
  PaSpalva: Array<string>;
  PaMedziaga: Array<string>;
};

export type SearchQueryType = {
  page: number;
  perPage: number;

  PaMedziaga: string[];
  PaForma: string[];
  PaSpalva: string[];
  categories: string[];
};

export const newSearchQuery = (query: Partial<SearchQueryType> = {}): SearchQueryType => ({
  page: query.page ?? 1,
  perPage: query.perPage ?? PER_PAGE,
  PaMedziaga: query.PaMedziaga ?? [],
  PaSpalva: query.PaSpalva ?? [],
  PaForma: query.PaForma ?? [],
  categories: query.categories ?? [],
});

type SearchableType = Pick<SearchQueryType, "PaMedziaga" | "PaForma" | "PaSpalva" | "categories">;

export const getSearchable = (query: SearchQueryType): SearchableType => ({
  PaMedziaga: query.PaMedziaga,
  PaSpalva: query.PaSpalva,
  PaForma: query.PaForma,
  categories: query.categories,
});

export const toggleAttribute = (
  query: SearchQueryType,
  attribute: keyof SearchableType,
  value: string,
): SearchQueryType => {
  if (query[attribute].indexOf(value) === -1) {
    return addAttribute(query, attribute, value);
  }

  return removeAttribute(query, attribute, value);
};

export const addAttribute = (
  query: SearchQueryType,
  attribute: keyof SearchableType,
  value: string,
): SearchQueryType => {
  return { ...query, [attribute]: [...query[attribute], value] };
};

export const removeAttribute = (
  query: SearchQueryType,
  attribute: keyof SearchableType,
  value: string,
): SearchQueryType => {
  return { ...query, [attribute]: query[attribute].filter((key) => key !== value) };
};

export function parseRouterQuery(url: string[] | string | undefined): SearchQueryType {
  if (url === undefined) {
    url = "";
  }
  const params = typeof url === "string" ? url.toLowerCase().split("/") : url;
  const result: SearchQueryType = newSearchQuery();
  const and = new RegExp("\\-(" + logicalOperators.and.join("|") + ")\\-", "g");
  const regExp = new RegExp(/\d+/);

  params.forEach((splitted) => {
    if (typeof splitted === "string" && regExp.test(splitted)) {
      const page = parseInt(splitted, 10);
      if (!isNaN(page)) {
        result.page = page;
      }
      return;
    }
    (Object.keys(filters) as Array<keyof typeof filters>).map((key) => {
      const filter = filters[key].pathname.find((pathname) => splitted.indexOf(pathname) === 0);
      if (filter) {
        result[key] = splitted
          .slice(filter.length + 1)
          .replace(and, "|")
          .split("|");
      }
    });
  });

  return result;
}
