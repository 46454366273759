import * as React from "react";
import { AttributeColor, AttributeShape, findAttribute } from "../../lib/find/products";
import { FormIcon } from "../attributes/Form";
import { Color, ColorCode } from "../attributes/Color";
import styles from "./Card.module.scss";
import Link from "next/link";
import { BaseProductType } from "../../lib/api/products";
import { formatPrice, isAvailable, parseNumber } from "../../lib/util/product";
import { productLink } from "../../lib/util/links";
import { Favorite } from "./Favorite";
import { AppContext } from "../context/AppContext";
import classNames from "classnames";
import Image from "next/legacy/image";
import { i18n } from "../../lib/util/i18n";

const priceOfProduct = (product: BaseProductType): string => {
  if (product.__typename === "SimpleProduct") {
    return formatPrice(product.price);
  }

  if (product.__typename === "VariableProduct") {
    const prices = { min: 0, max: Number.MAX_VALUE };

    product.variations.nodes.forEach((variation) => {
      const price = parseNumber(variation.price);
      if (price > prices.min) {
        prices.min = price;
      }

      if (price < prices.max) {
        prices.max = price;
      }
    });

    if (prices.min === prices.max) {
      return formatPrice(prices.min);
    }

    if (prices.max === Number.MAX_VALUE) {
      return "";
    }

    return i18n.t("components.card.prices", { price: formatPrice(prices.max) });
  }

  return "";
};

export const Card = ({ product }: { product: BaseProductType }): JSX.Element => {
  const [variant, setVariant] = React.useState(null);
  const { favorites } = React.useContext(AppContext);

  let outOfStock = !(product.__typename === "SimpleProduct" ? isAvailable(product) : true);
  if (product.__typename === "VariableProduct") {
    outOfStock = !product.variations.nodes.some((v) => isAvailable(v));
  }

  return (
    <Link href={productLink(product)} passHref>
      <div className={classNames(styles.outerCard, { [styles.outOfStock]: outOfStock })}>
        <div className={styles.leftPanel}>
          {product.__typename === "VariableProduct" &&
            product.variations.nodes.map((p) =>
              p.attributes.nodes.map((a) => (
                <div key={a.value} onMouseEnter={() => p.image && setVariant(p)} onMouseLeave={() => setVariant(null)}>
                  <Color className={styles.color} colors={a.value} />
                </div>
              )),
            )}

          {product.__typename !== "VariableProduct" && (
            <Color
              className={styles.color}
              colors={(findAttribute(product.attributes?.nodes || [], AttributeColor)?.options as ColorCode[]) || []}
            />
          )}

          <div style={{ paddingTop: "3px", float: "left", marginRight: 10, opacity: 0.5 }}>
            <FormIcon
              svgProps={{ style: { width: "40px", height: "20px" } }}
              options={findAttribute(product.attributes?.nodes || [], AttributeShape)?.options || []}
            />
          </div>
          <div className={styles.productInfo}>{product.name}</div>
          <div
            className={classNames(styles.favorite, {
              [styles.love]: favorites.find((id) => id === product.databaseId),
            })}
          >
            <Favorite productId={product.databaseId} />
          </div>
        </div>

        <div className={styles.bottomLeftPanel}>
          <div className={styles.productInfo}>
            {product.__typename === "VariableProduct" && variant ? formatPrice(variant.price) : priceOfProduct(product)}
          </div>
        </div>

        {(product.__typename !== "VariableProduct" || !variant) && product.image && product.image.sourceUrl && (
          <div className={styles.productImage}>
            <div className={styles.productImageInner}>
              <Image
                alt={product.image.altText || ""}
                src={product.image.sourceUrl}
                objectFit="contain"
                layout="fill"
              />
            </div>
          </div>
        )}

        {product.__typename === "VariableProduct" && variant && (
          <div className={styles.productImage}>
            <div className={styles.productImageInner}>
              <Image
                alt={variant.image.altText || ""}
                src={variant.image.sourceUrl}
                objectFit="contain"
                layout="fill"
              />
            </div>
          </div>
        )}
      </div>
    </Link>
  );
};
