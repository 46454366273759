import { BaseProductAttributeFragment } from "../api/types";

export const AttributeShape = "Forma";
export const AttributeColor = "Spalva";
export const AttributeMadeFrom = "Medžiaga";

export const findAttribute = (
  attributes: Array<BaseProductAttributeFragment>,
  attribute: string,
): BaseProductAttributeFragment | null | undefined => {
  return attributes.find(({ name }) => name.toLowerCase() === attribute.toLowerCase());
};
