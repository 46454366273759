import * as React from "react";
import Aviator from "./form/aviator.svg";
import Mouse from "./form/mouse.svg";
import Square from "./form/square.svg";
import Rect from "./form/rectangle.svg";
import Round from "./form/round.svg";

type Props = {
  options: Array<string>;
  svgProps: React.ImgHTMLAttributes<"img">;
};

export const FormIcon = ({ options, svgProps }: Props): JSX.Element | null => {
  if (options.indexOf("kates-akys") !== -1) {
    return <Mouse {...svgProps} />;
  }

  if (options.indexOf("aviatoriaus") !== -1) {
    return <Aviator {...svgProps} />;
  }

  if (options.indexOf("kvadratiniai") !== -1) {
    return <Rect {...svgProps} />;
  }

  if (options.indexOf("apvalus") !== -1) {
    return <Round {...svgProps} />;
  }

  if (options.indexOf("staciakampiai") !== -1) {
    return <Square {...svgProps} />;
  }

  return null;
};

FormIcon.defaultProps = {
  svgProps: {},
};
