const chars = {
  ą: "a",
  č: "c",
  ę: "e",
  ė: "e",
  į: "i",
  š: "s",
  ų: "u",
  ū: "u",
  ž: "z",
};

export const replaceSymbols = (str: string): string => {
  Object.keys(chars).map((s) => (str = str.replace(new RegExp(s, "g"), chars[s])));
  return str;
};
